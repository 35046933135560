module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Budocloud","short_name":"Budocloud","description":"BuduCloud’s CRM & ERP are tailored specifically for your business needs providing you with a flexible & highly adaptable user-friendly experience.","lang":"en","display":"standalone","icon":"src/images/favicon.png","start_url":"/","background_color":"#E5F1FF","theme_color":"#007FE1","localize":[{"start_url":"/ar/","lang":"ar","name":"بودوكلاود","short_name":"بودوكلاود","description":"نحن شركة متخصصة في مجال نظم وإدارة المعلومات، نقوم بتصميم الأنظمة الإدارية المتكاملة باستخدام أحدث التقنيات التي تساعدك على إدارة أعمالك بسهولة وتحقيق أهداف مؤسستك."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1dc33726a63ed17f5cf9e497ef33fadc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
